import React, { useState, useEffect } from "react";
import { isEmpty } from 'lodash';
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import { getOgImage } from "../../utils/functions";
import CategoryPostList from "../../components/categoryPost/categoryPostList";
import PostsListbox from "../../components/postsList/PostsListbox";
import "./style.scss"
import Button from "../../components/button/Button";


const CategoryPage = (props) => {
  const {
    pageContext: {
      title, seo, uri, content, type, name
    }
  } = props;

  const { code: locale } = props.pageContext.wpmlTranslated.current[0];
  const lang = locale.substring(0, 2);

  const { translated, acfKnowledgeBase, categoriesList, posts } = props.pageContext
  const { headingCategories, searchPlaceholder } = acfKnowledgeBase
  const titlePage = type === 'cat' ? name : title;
  const countLoadMorePost = 6;
  const [catActive, setCatActive] = useState('');
  const [isSearch, setIsSearch] = useState(false);
  const [postsList, setPostList] = useState({ nodes: [] });
  const [loading, setLoading] = useState(false)
  const [pageIndex, updatePageIndex] = useState(countLoadMorePost)
  const [loadMore, setLoadMore] = useState(false)

  useEffect(() => {
    setPostList(posts);
    setCatActive(titlePage)
    setLoadMore(pageIndex < posts.nodes.length);
  }, [])

  const showMorePost = () => {
    updatePageIndex(pageIndex + countLoadMorePost);
    setLoadMore(pageIndex + countLoadMorePost < posts.nodes.length)
  };

  return (
    <Layout lang={lang} page="category" translated={translated}>
      {
        !isEmpty(props.pageContext) ? (
          <>
            <SEO
              title={titlePage}
              seoData={seo}
              uri={uri}
              lang={lang}
              page={"category"}
              // header={ siteTitle: 'Gatsby WooCommerce Theme' }}
              openGraphImage={getOgImage(seo)}
            />
            <div className="category page">
              <section className="description">
                <div className="container container--small">
                  <div className="page__hero">
                    <h1 className="heading heading--xl">
                      {title}
                    </h1>
                  </div>
                  <div className="content" dangerouslySetInnerHTML={{ __html: content }}></div>
                </div>
              </section>
              <CategoryPostList
                catHeading={headingCategories}
                catList={categoriesList}
                catSearchInput={searchPlaceholder}
                catActive={catActive}
                setPostList={setPostList}
                loading={loading}
                setLoading={setLoading}
                setCatActive={setCatActive}
                setIsSearch={setIsSearch}
              />
              <section className="postList">
                <div className="container container--small">
                  {loading ? (<></>) : (
                    postsList.nodes.length ? (
                      <div className="grid grid--3">
                        {postsList.nodes.map((post, index) => {
                          const img = post.featuredImage ? post.featuredImage.node : ''
                          if (pageIndex > index) {
                            return (
                              <PostsListbox
                                key={post.id}
                                img={img}
                                name={post.title}
                                link={post.uri}
                                date={post.date}
                                excerpt={post.excerpt}
                              />
                            )
                          }
                          else {
                            return '';

                          }
                        })}
                      </div>
                    ) : (
                      <h2 className="heading heading--big">Brak postów</h2>
                    )
                  )}
                  {loadMore && (
                    <div className="postList__loadmore">
                      <Button title='WIĘCEJ'
                        url="#"
                        action={showMorePost} />
                    </div>
                  )}
                </div>
              </section>

            </div>
          </>
        ) : (
          <div>Something went wrong</div>
        )
      }
    </Layout>
  )
};
export default CategoryPage;

